import './App.css';
import React from "react";
import axios from 'axios';
import { BrowserRouter as Router, Switch, Route, Link, Redirect } from "react-router-dom";
import "./i18n";

import "./stylesheets/main.css";

import MooringMap from './screens/MooringMap';

function App() {

  axios.defaults.baseURL = process.env.REACT_APP_API_HOST;

  return (
    <div className="App">
      <header className="App-header">
        <Router>
          {/* <MooringMap /> */}
          <Switch>
            <Route path="/:marina_id" component={MooringMap} />
            {/* <Route path="test/:marina_id" component={MooringMap} /> */}
          </Switch>
        </Router>
      </header >
    </div >
  );
}

export default App;
