import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import { useTranslation, initReactI18next } from "react-i18next";



const detectorOptions = {

    // order and from where user language should be detected
    order: ['querystring', 'localStorage', 'navigator', 'sessionStorage', 'cookie', 'htmlTag', 'path', 'subdomain'],

    load: 'languageOnly', // only load languages and nothing else

    // keys or params to lookup language from
    lookupQuerystring: 'lng',
    lookupCookie: 'i18next',
    lookupLocalStorage: 'i18nextLng',
    lookupSessionStorage: 'i18nextLng',
    lookupFromPathIndex: 0,
    lookupFromSubdomainIndex: 0,

    // cache user language on
    caches: ['localStorage', 'cookie'],
    excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

    // optional expire and domain for set cookie
    cookieMinutes: 10,
    cookieDomain: process.env.REACT_APP_WEB_APP_LINK,


    // optional htmlTag with lang attribute, the default is:
    htmlTag: document.documentElement,

    // optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
    cookieOptions: { path: '/', sameSite: 'strict' }

}

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(LanguageDetector)
    .use(Backend) // add this to use i18next-http-backend
    .init({
        backend: { // configure the backend
            loadPath: 'https://api.boatpark.app/v2.10/translations/get_json?language={{lng}}&project=boatpark_web', // the url to your json files
        },
        react: {
            useSuspense: true,
            bindI18n: 'languageChanged', // i am changing this as I dont wanna any flickering on language change
            wait: true,
        },
        fallbackLng: "en",
        returnObjects: true,
        interpolation: {
            escapeValue: false,
        },
        detection: detectorOptions,
    });
export default i18n;
