import axios from "axios";
import moment from "moment";
axios.defaults.baseURL = process.env.REACT_APP_API_HOST;



export function getBoundsForMarina(account_id, callback, errorcallback) {

    const url = `moorings/get_bounds_for_marina?account_id=${account_id}`
    axios
        .get(url)
        .then((response) => {
            if (response.status == 200) {
                var bounds = response.data;
                callback(bounds);
            }
        })
        .catch((err) => {
            errorcallback(err.response);
        });
}

export function getPublicMooringsForMarina(account_id, callback, errorcallback) {

    const url = `moorings/get_public_moorings_for_marina?account_id=${account_id}`
    axios
        .get(url)
        .then((response) => {
            if (response.status == 200) {
                var bounds = response.data;
                callback(bounds);
            }
        })
        .catch((err) => {
            errorcallback(err.response);
        });
}



export const searchMoorings = (from_date, to_date, bounds, filter, callback, errorcallback) => {

    from_date = moment(from_date).format("YYYY-MM-DD");
    to_date = moment(to_date).format("YYYY-MM-DD");

    const search_filter = {
        ...filter,
    }

    const url = `moorings/search_moorings?from_date=${from_date}&to_date=${to_date}`
    axios
        .get(url, {
            params: {
                bounds: JSON.stringify(bounds),
                filter: JSON.stringify(search_filter)
            }
        })
        .then(response => {
            if (response.status == 200) {
                const moorings = response.data
                callback(moorings);
            }
        })
        .catch(err => {
            errorcallback(err);
        });
};



export const getPublicMooringInfo = (mooring_id, from_date, to_date, callback, errorcallback) => {

    from_date = moment(from_date).format('YYYY-MM-DD');
    to_date = moment(to_date).format('YYYY-MM-DD');
    axios.get(`moorings/get_public_info?mooring_id=${mooring_id}&from_date=${from_date}&to_date=${to_date}`)
        .then(response => {
            if (response.status == 200) {
                const mooring = response.data;
                callback(mooring);
            }
        })
        .catch(err => {
            errorcallback(err);
        });
};