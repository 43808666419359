import axios from "axios";

export const getPublicMapOverlays = (callback, errorcallback) => {

    axios
        .get(`/account/get_public_map_overlays`)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
        })
        .catch((err) => {
            errorcallback(err);
        });
};




