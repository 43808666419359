import React from 'react';
import PropTypes from 'prop-types';


const defaultStyles = {

    labelDefault: {
        color: 'var(--main-gray)',
        fontSize: 14,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },

}

function FormInput(props) {
    return (
        <div className="form_input" id={`${props.id}_container`} >

            <label className="label" style={{ ...defaultStyles.label, ...props.labelStyle }}>
                {props.uppercaselabel ? props.label.toUpperCase() : props.label} 
                {props.unit && ` [${props.unit}]`}
            </label>

            < input
                label={props.label}
                aria-label={props.label}
                {...props}
                uppercaselabel={null}
                last={null}
                unit={null}
            />
            {!props.last &&
                <div className="separator_horizontal" />}
        </div >
    );
}

FormInput.defaultProps = {
    value: "",
    onChange: () => { },
    unit: null,
    last: null,
    uppercaselabel: true,
};

FormInput.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    onChange: PropTypes.elementType,
    value: PropTypes.any,
    unit: PropTypes.string,
    last: PropTypes.bool,
    labelStyle: PropTypes.object,
    uppercaselabel: PropTypes.bool,
};



export default FormInput;
