import React from "react";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  Circle,
  GroundOverlay,
} from "@react-google-maps/api";
import moment from "moment";
import { getPublicMooringInfo } from "../actions/moorings";
import { useTranslation } from "react-i18next";
import { withSearchParams } from "../components/HOCs";
import { useEffect } from "react";

import p_active from "../assets/p_active.png";
import PropTypes from "prop-types";

const containerStyle = {
  width: "100vw",
  height: "100vh",
};

const styles = {
  calloutContainer: {
    position: "absolute",
    left: "60%",
    transform: "translate(-60%, 0)",
    bottom: 80,
    width: "80%",
    maxWidth: 500,
    zIndex: 10,
    transition: "opacity 0.2s ease-in-out", // transition for opacity
  },
  calloutImage: {
    width: 80,
    position: "absolute",
    left: -40,
    top: 38,
  },
  callout: {
    background: "#003066",
    marginTop: 10,
    padding: 10,
    borderRadius: 10,
    textAlign: "left",
    paddingLeft: 45,
  },
  calloutTitle: {
    fontSize: 20,
    fontWeight: "bold",
    marginTop: 0,
    marginBottom: 10,
  },

  calloutText: {
    fontSize: 14,
    // fontWeight: "bold",
    marginTop: 0,
    marginBottom: 10,
  },
  red: {
    color: "red",
  },
};

const greenPin = {
  url: "https://mt.google.com/vt/icon?psize=30&font=fonts/arialuni_t.ttf&color=ff304C13&name=icons/spotlight/spotlight-waypoint-a.png&ax=43&ay=48&text=%E2%80%A2", // url
};

function MapComponent(props) {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });

  const [map, setMap] = React.useState(null);
  const [selectedMooring, setSelectedMooring] = React.useState(false);
  const [blueDot, setBlueDot] = React.useState({});

  const { t, i18n } = useTranslation();

  useEffect(() => {
    onPinClicked(props.otherMooring);
  }, [props.otherMooring]);

  const onLoad = React.useCallback(function callback(map) {
    const google = window.google;

    // Fit bounds for all shown pins
    var bounds = new google.maps.LatLngBounds();
    var min = new google.maps.LatLng(
      props.bounds.min_lat,
      props.bounds.min_lng
    );
    var max = new google.maps.LatLng(
      props.bounds.max_lat,
      props.bounds.max_lng
    );
    bounds.extend(min);
    bounds.extend(max);
    map.fitBounds(bounds, { top: 150 });

    // Get google api icons
    const blueDot = {
      fillColor: "#4285F4",
      fillOpacity: 1,
      path: window.google.maps.SymbolPath.CIRCLE,
      scale: 8,
      strokeColor: `rgb(255,255,255)`,
      strokeWeight: 2,
    };
    setBlueDot(blueDot);

    setMap(map);
    props.onMapRef(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const onDragEnd = (e) => {
    if (!map) return;
    let ne = map.getBounds().getNorthEast();
    let sw = map.getBounds().getSouthWest();

    const bounds = {
      min_lng: sw.lng(),
      min_lat: sw.lat(),
      max_lng: ne.lng(),
      max_lat: ne.lat(),
    };
    props.onDragEnd(bounds);
  };

  const onPinClicked = (mooring) => {
    if (!map) return;
    const { from_date, to_date } = props;
    getPublicMooringInfo(
      mooring.mooring_id,
      from_date,
      to_date,
      (mooring) => {
        console.log("mooring...dsf.sdf.", mooring);
        const center = {
          lat: mooring.latitude,
          lng: mooring.longitude,
        };
        map.panTo(center);
        setSelectedMooring(mooring);
      },
      (error) => {}
    );
  };

  const { userLocation } = props;

  var bookingLink = selectedMooring
    ? `${process.env.REACT_APP_WEB_APP_LINK}FastBooking/${selectedMooring.mooring_id}/?`
    : "";
  if (props.filter.length_low)
    bookingLink = `${bookingLink}l=${props.filter.length_low}`;
  if (props.filter.beam_low)
    bookingLink = `${bookingLink}&b=${props.filter.beam_low}&`;

  var daily_rate_text = selectedMooring?.daily_rate?.daily_rate;
  if (typeof daily_rate_text === "number") {
    daily_rate_text = daily_rate_text.toFixed(2);
  }

  const totalPriceText = selectedMooring?.daily_rate?.daily_rate
    ? `${selectedMooring.payment_currency} ${daily_rate_text}`
    : "Check next screen";
  const bookingRequestInfo = selectedMooring?.needs_booking_confirmation
    ? `${t("BpmsMooringMap.confirmationNeeded", {
        n: selectedMooring?.auto_confirm_delay,
      })}`
    : "";

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      tilt={0}
      zoom={10}
      onLoad={onLoad}
      onClick={() => {
        setSelectedMooring(null);
        props.onMapClicked();
      }}
      onDragEnd={onDragEnd}
      onZoomChanged={onDragEnd}
      onUnmount={onUnmount}
      options={{
        draggable: true, // make map draggable
        keyboardShortcuts: false, // disable keyboard shortcuts
        scaleControl: true, // allow scale controle
        scrollwheel: true, // allow scroll wheel
        mapTypeId: "satellite",
        streetViewControl: false,
        panControl: false,
        zoomControl: false,
        mapTypeControl: false,
        fullscreenControl: false,
      }}
    >
      {!props.otherMooring &&
        props.moorings?.map((mooring) => (
          <Marker
            label={
              map?.zoom >= 17
                ? {
                    className: "labelClass",
                    color: "#00aaff",
                    fontWeight: "bold",
                    fontSize: "14px",
                    text: mooring.berth_number,
                  }
                : ""
            }
            title={mooring.berth_number}
            key={mooring.mooring_id}
            position={{
              lat: parseFloat(mooring.latitude),
              lng: parseFloat(mooring.longitude),
            }}
            icon={greenPin}
            onClick={() => {
              onPinClicked(mooring);
            }}
          />
        ))}

      {props.otherMooring && (
        <Marker
          label={
            map?.zoom >= 17
              ? {
                  className: "labelClass",
                  color: "#00aaff",
                  fontWeight: "bold",
                  fontSize: "14px",
                  text: props.otherMooring.berth_number,
                }
              : ""
          }
          title={props.otherMooring.berth_number}
          key={props.otherMooring.mooring_id}
          position={{
            lat: parseFloat(props.otherMooring.latitude),
            lng: parseFloat(props.otherMooring.longitude),
          }}
          icon={greenPin}
          onClick={() => {
            onPinClicked(props.otherMooring);
          }}
        />
      )}

      {props.map_overlays &&
        props.map_overlays.map((map_overlay, index) => (
          <GroundOverlay
            key={index}
            bounds={{
              north: Math.max(map_overlay.lat1, map_overlay.lat2),
              south: Math.min(map_overlay.lat1, map_overlay.lat2),
              east: Math.max(map_overlay.lng1, map_overlay.lng2),
              west: Math.min(map_overlay.lng1, map_overlay.lng2),
            }}
            url={map_overlay.url}
          />
        ))}

      {userLocation && ( // Show blue dot and blue circle around user location
        <>
          <Marker
            key={0}
            position={{
              lat: userLocation.latitude,
              lng: userLocation.longitude,
            }}
            icon={blueDot}
            style={{ width: 10 }}
          />
          <Circle
            center={{ lat: userLocation.latitude, lng: userLocation.longitude }}
            radius={userLocation.accuracy}
            options={{
              fillColor: "#61a0bf",
              strokeColor: "#4285F4",
              strokeOpacity: 0.4,
              strokeWeight: 1,
              zIndex: 1,
            }}
          />
        </>
      )}

      <div
        style={{
          ...styles.calloutContainer,
          opacity: selectedMooring ? 1 : 0,
        }}
      >
        {selectedMooring && (
          <>
            <img style={styles.calloutImage} alt="bp_icon" src={p_active}></img>
            <div style={styles.callout}>
              <p
                style={styles.calloutTitle}
              >{`${selectedMooring.harbour_name} ${selectedMooring.berth_number}`}</p>
              <p style={styles.calloutText}>{`${t(
                "BpmsMooringMap.dimensions"
              )} ${selectedMooring.max_length?.toFixed(
                2
              )}m / ${selectedMooring.max_beam?.toFixed(
                2
              )}m / ${selectedMooring.max_draught?.toFixed(2)}m`}</p>
              <p style={styles.calloutTitle}>{`${t(
                "BpmsMooringMap.available"
              )}`}</p>
              <p style={styles.calloutText}>{`${moment().format(
                "DD.MM.YYYY"
              )} - ${moment().add(1, "day").format("DD.MM.YYYY")}`}</p>
              {!selectedMooring.price_by_size && (
                <p style={styles.calloutText}>{`${t(
                  "BpmsMooringMap.price"
                )}: ${totalPriceText}`}</p>
              )}
              <p
                style={{ ...styles.calloutText, ...styles.red }}
              >{`${bookingRequestInfo}`}</p>
              <p style={styles.calloutTitle}>
                <a
                  onClick={(e) => {}}
                  style={{ color: "#0076ff" }}
                  rel="noreferrer"
                  target="_blank"
                  href={bookingLink}
                >
                  {t("BpmsMooringMap.bookNow")}
                </a>
              </p>
            </div>
          </>
        )}
      </div>

      <></>
    </GoogleMap>
  ) : (
    <></>
  );
}

MapComponent.propTypes = {
  onMapRef: PropTypes.func.isRequired,
  from_date: PropTypes.any.isRequired,
  to_date: PropTypes.any.isRequired,
  map_overlays: PropTypes.array,
  bounds: PropTypes.object.isRequired,
  moorings: PropTypes.arrayOf(PropTypes.object).isRequired,
  onDragEnd: PropTypes.func.isRequired,
  onMapClicked: PropTypes.func.isRequired,
  geoLocationAvailable: PropTypes.bool.isRequired,
  userLocation: PropTypes.any.isRequired,
};
export default React.memo(withSearchParams(MapComponent));
