import React from 'react'
import { useLocation } from 'react-router-dom'

export const withSearchParams = (Component) => {
    return (props) => {
        const search = useLocation().search;
        const searchParams = new URLSearchParams(search)

        return <Component searchParams={searchParams} {...props} />;
    };
};
