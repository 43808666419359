import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_API_HOST;



export function getPublicAccountInfo(account_id, callback, errorcallback) {

    const url = `account/get_public_account_info?account_id=${account_id}`
    axios
        .get(url)
        .then((response) => {
            if (response.status == 200) {
                var account = response.data;
                let communication_language = String(account.communication_language).toLowerCase();

                // i18next.changeLanguage(communication_language);

                callback(account);
            }
        })
        .catch((err) => {
            errorcallback(err.response);
        });
}
